import React, { useState } from 'react';
import './ForgotPassword.css';
import axios from "axios";
import {Link} from "react-router-dom";



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        console.log(email);
        axios
          .post(`http://34.163.83.48/auth/forgot-password/${email}`)
          .then((res) => {
            console.log(res.data);
            setEmailError(false);
            setShowSuccessAlert(true);
          })
          .catch((error) => {
            setEmailError(true);
            if (error.response) {
              
              console.log("Sunucu Hata:", error.response.data.message);
              
            } else if (error.request) {
              
              console.log("İstek Hatası:", error.request);
            } else {
              
    
              console.log("Hata:", error.message);
            }
          });
      };

    return (
        <div className="forgotPassword-container">
           
            <h1 className="forgotPassword-title">Şifremi Unuttum</h1>
            <p className="forgotPassword-subtitle">Email adresiniz ile şifrenizi sıfırlayarak yeniden giriş yapabilirsiniz</p>
            <form onSubmit={handleSubmit} className="forgotPassword-form">
                <label className="forgotPassword-label" htmlFor="email">Email *</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder='@example.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="forgotPassword-input"
                    required
                />
                <button type="submit" className="forgotPassword-button">Şifremi Sıfırla</button>
            </form>
            <p className="forgotPassword-loginLink">
                Zaten bir hesabınız var mı? <a href="/login">Giriş Yap</a>
            </p>
        

        </div>
    );
};

export default ForgotPassword;