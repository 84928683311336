import React, { useContext, useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import { CategoryContext } from '../context/CategoryContext.js';
import "../assest/Sidebar.css";
import { ProductContext } from '../context/ProductContext.js';
import { FaBars, FaTimes } from 'react-icons/fa';

const Sidebar =()=>{
    const{state:categoryState, selectedCategory}=useContext(CategoryContext);
    const{categories, loading, error}=categoryState;
    const [openCategories, setOpenCategories] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); 


    const toggleCategory = (categoryId) => {
      if (openCategories.includes(categoryId)) {
        setOpenCategories(openCategories.filter((id) => id !== categoryId));
      } else {
        setOpenCategories([...openCategories, categoryId]);
      }
      selectedCategory(categoryId);
    };

    
    const ustKategoriler = categories.filter((kategori) => kategori.ust_kategori === 0 & kategori.ust_menu === 1);
    console.log(ustKategoriler);
    const hasSubcategories = (categoryId) => {
      return categories.some((kategori) => kategori.ust_kategori === categoryId);
    };
   
    if (loading) return <div>Yükleniyor...</div>;
    if (error) return <div>Hata: {error}</div>;

return (
  <>
   
   <div className='sidebar-toggle' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                {isSidebarOpen ? <FaTimes /> : <FaBars />}Kategoriler
            </div>
            <div className={`category-container ${isSidebarOpen ? 'open' : ''}`}> 
                <div className='titlee'>
                    <h3>Kategoriler</h3>
                </div>
                <div className='category-listt'>
                 <ul>
                     {ustKategoriler.map((kategori) => (
                         <li key={kategori.id}>
                             <a href={hasSubcategories(kategori.sef) ? "#" : `${kategori.sef}` }
                                onClick={(e) => {
                                    if (hasSubcategories(kategori.id)) {
                                        e.preventDefault();
                                        toggleCategory(kategori.id);
                                    }
                                }}>
                                 {kategori.baslik}
                             </a>
                             {openCategories.includes(kategori.id) && hasSubcategories(kategori.id) && (
                                 <ul>
                                     {categories
                                         .filter((altKategori) => altKategori.ust_kategori === kategori.id)
                                         .map((altKategori) => (
                                             <li key={altKategori.id}>
                                                 <a href={`${altKategori.sef}`}>{altKategori.baslik}</a>
                                             </li>
                                         ))}
                                 </ul>
                             )}
                         </li>
                     ))}
                 </ul>
             </div>
            </div>
 
  </>
);
};
export default Sidebar;
