import React, {useEffect } from 'react';
import "../assest/PopUp.css";

const Popup = ({ message, onContinue, onGoToCart, duration, closePopup }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
          onContinue(); 
        }, duration);
    
        
        return () => clearTimeout(timer);
      }, [duration, onContinue]);

      const handleOutsideClick = (e) => {
        if (e.target.className === 'popup-overlay') {
          closePopup();
        }
      };
  return (
    
    <div className="popup-overlay" onClick={handleOutsideClick}>
      <div className="popup-content">
        
        <h3>{message}</h3>
        <div className="popup-buttons">
          <button className="continue-btn" onClick={onContinue}>
            Alışverişe Devam Et
          </button>
          <button className="go-cart-btn" onClick={onGoToCart}>
            Sepete Git
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;