import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserProvider } from "../../context/UserContext";


const PaymentResult=()=>{
	const navigate = useNavigate();
    const  {status}=useParams();
return(

    <div style={{ textAlign: 'center', marginTop: '50px' }}>
    {status === 'success' && (
      <div style={{ color: 'green' }}>
        <h1>Ödeme Başarılı</h1>
        <p>Ödemeniz başarıyla tamamlandı. Teşekkür ederiz</p>
        <a href="/">Ana Sayfaya Dön</a>
      </div>
    )}

    {status === 'failure' && (
      <div style={{ color: 'red' }}>
        <h1>Ödeme Başarısız</h1>
        <p>Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.</p>
        <a href="/">Ana Sayfaya Dön</a>
      </div>
    )}
  </div>

);

}
export default PaymentResult;