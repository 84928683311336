import React, { useContext } from 'react';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { FaHeart } from 'react-icons/fa';
import "./Favorite.css";



const Favorite = () => {
  const { favorites, removeFavorite} = useContext(FavoriteContext);
 
  
  
    return (
      
      <div className='favorites'>
           <div className='favorite-title'>
           <h2><FaHeart style={{ marginRight: '5px', color: 'red', }} /> 
           Favorilerim</h2></div> 
      {favorites.length === 0 ? (
          <p>Favori ürün bulunmamaktadır.</p>
      ) : (
         <div className="favori-container">
          
            <div className="favori-grid">
            
            {favorites.map(product => (
                <div key={product.sef} className="favori-card">
                    <div className="favori-img-container">
                    <a href={`/urun/${product.sef}`}>
                    <img src={`https://panel.bikuyum.com/upload/${product.image.i1}`} alt={product.baslik}  onError={(e) => {
                        e.target.onerror = null;  }}/></a>
                         <span 
                                        onClick={() => removeFavorite(product)} 
                                        style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '5px' }}
                                    >
                                        <FaHeart color="red" />
                                    </span></div>
                    <div className="favori-card-responsive-info">
            <h3 className="favori-card-responsive-name">
                <a href={`/urun/${product.sef}`}>{product.baslik}</a>
              </h3>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <div className="favori-pricee">
        {product.fiyat && product.eski_fiyat !== product.fiyat ? (
            <>
                <h2 className="favori-originall-price" style={{ textDecoration: 'line-through' }}>
                    {product.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="favori-discountedd-price">
                    {product.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="favori-originall-price">
                {product.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
           
            {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
             </div>
                </div>
            ))}
            </div>
            </div>
         
      )}
  </div>
  
    );
};

export default Favorite;