import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../context/ProductContext.js';
import { Container } from '@mui/material';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import "./ProductList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';

const ProductList = () => {
    const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);
    const { state, fetchProducts, dispatch } = useContext(ProductContext);
    const { products, loading, error } = state;
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
  
    
    const isFavorite = (product) => {
        return favorites.some(favProduct => favProduct.id === product.id);
    };

    const handleFavoriteToggle = (event, product) => {
        event.stopPropagation(); 
        if (isFavorite(product)) {
            removeFavorite(product);
        } else {
            addToFavorite(product);
        }
    };
    const handleProductClick = (sef) => {
        window.location.href = `/urun/${sef}`;
    };
   
    useEffect(() => {
        const loadProducts = async () => {
            if (isFetching) return;  
            setIsFetching(true);  
            await fetchProducts(currentPage);
            setIsFetching(false); 
        };
        loadProducts();
    }, [currentPage ]);

    useEffect(() => {
        dispatch({ type: 'RESET_PRODUCTS' });
        
    }, []);

    
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerWidth < 768 ? 1300 : 700;
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight  - scrollThreshold &&
                !isFetching &&
                !loading
            ) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [ loading]);



    if (loading) return <div>Yükleniyor...</div>;
    if (error) return <div>Hata: {error}</div>;

    
    return (
        <>
        
        <div className="products-container">
            <div className="productt-grid">
            
            {products.map((product, index) => (
                <div  
                key={`${product.id}-${index}`}
                className="productt-card"
                onClick={() => handleProductClick(product.sef)}
                style={{ cursor: 'pointer' }}>
                    <div className="productt-img-container" >
                  
                    <img src={`https://panel.bikuyum.com/upload/${product.image.i1}`} alt={product.baslik}  onError={(e) => {
                        e.target.onerror = null;  }}/>
                          <div 
                                        onClick={(e) => handleFavoriteToggle(e, product)} 
                                        className="favorite-iconn" 
                                    >
                                        <FontAwesomeIcon
                                icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: '24px' }}
                            />
                                    </div>
                        </div>
                    <div className="productt-card-responsive__info">
            <h3 className="productt-card-responsive__name">
                {product.baslik}
              </h3>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <div className="pricee">
        {product.fiyat && product.eski_fiyat !== product.fiyat ? (
            <>
                <h2 className="originall-price" style={{ textDecoration: 'line-through' }}>
                    {product.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="discountedd-price">
                    {product.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="originall-price">
                {product.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
           
           
           
           
           
            {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
             </div>
                </div>
            ))}
            </div>
               
      {isFetching && (
        <div className='loader-container'>  
        <div className="loader"></div></div>
      )}
            </div>
            
            </>
    );
};

export default ProductList;