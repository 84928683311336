import { useEffect, useNavigate } from "react";
import React  from "react";

const Magaza=()=>{

    useEffect(() => {
        window.location.href = "https://panel.bikuyum.com/magaza";
      }, []);
    
      return null; 
    };
export default Magaza;