import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Payment.css'; 
import {PaymentContext} from "../../context/PaymentContext.js";
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Payment = () => {
	const navigate = useNavigate();
  const {
    state: { cardNumber, totalAmount, cardHolderName, expiryDate, cvc, installments, commissionRate, selectedInstallment, error, paymentSuccess },
    setCardNumber,
    setCardHolderName,
    setExpiryDate,
    setCvc,
    setTotalAmount,
    fetchCommissionRates,
    fetchInstallments,
    setSelectedInstallment,
    processPayment,  
  } = useContext(PaymentContext);
  const location = useLocation();
  const {total } = location.state || {}; 
  const [isOpen, setIsOpen] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedInstallmentData, setSelectedInstallmentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFocus = () => setIsFormActive(true);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (total) {
      setTotalAmount(total);
    }
    fetchCommissionRates();
  }, []);
  useEffect(() => {
    
    if (cardNumber.length == 8 || cardNumber.length == 16) {
      fetchInstallments(cardNumber);
    }  else {
      
      setSelectedInstallment(null);
    }
  }, [cardNumber]);

  const validateForm = () => {
    const newErrors = {};
    if (!cardNumber || cardNumber.length !== 16) {
      newErrors.cardNumber = 'Geçerli bir kart numarası giriniz';
    }
    if (!cardHolderName || cardHolderName.trim().length < 5) {
      newErrors.cardHolderName = 'Geçerli bir kart sahibi adı giriniz';
    }
    if (!expiryDate || !expiryDate.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)) {
      newErrors.expiryDate = 'Geçerli bir son kullanma tarihi giriniz (AA/YY)';
    }
    if (!cvc || !cvc.match(/^[0-9]{3}$/)) {
      newErrors.cvc = 'Geçerli bir CVC giriniz';
    }
    if (!selectedInstallmentData) {
      newErrors.installment = 'Lütfen bir taksit seçiniz';
    }
    return newErrors;
  };
  
  const handleCardNumberChange = (e) => {
    let newCardNumber = e.target.value.replace(/\D/g, ''); 
  if (newCardNumber.length > 0) {
    newCardNumber = newCardNumber.slice(0, 16); 
  }
  setCardNumber(newCardNumber);
  };

  const handleInstallmentChange = (e) => {
    const selectedValue = Number(e.target.value);
    setSelectedInstallment(selectedValue);
    const selected = installments.find(inst => inst.count === selectedValue);
    setSelectedInstallmentData(selected);
    setErrors(prev => ({ ...prev, installment: '' }));
  };
  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value);
  };
  const handleExpiryDateChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.length >= 2) {
      input = input.slice(0, 2) + '/' + input.slice(2); 
    }
    setExpiryDate(input);
  };

  useEffect(() => {
    if (total) {
      setTotalAmount(total); 
    }
  }, [total]);

  const handleCvcChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 3);
    setCvc(value);
    setErrors(prev => ({ ...prev, cvc: '' }));
  };
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);
    try {
      const { total, customerForm, cart } = location.state || {};


	const paymentData = {
	  
	  kart_numarasi: cardNumber,
	  isim: cardHolderName, 
	  son_kullanma: expiryDate,
	  ccv: cvc,
	  taksit_sayisi: selectedInstallmentData.count,
	  odenecek_tutar: selectedInstallmentData.totalAmount,
	  musteriForm: customerForm,
	  sepet: cart
	};
  const response = await processPayment(paymentData);
      
  if (response.success) {
    
    if (response.redirect_url) {
      
      window.location.replace(response.redirect_url);
      return;
    } else {
      
      navigate('/success', { 
        state: { 
          orderId: response.orderId,
          amount: selectedInstallmentData.totalAmount 
        }
      });
    }
      } else {
        throw new Error(response.message || 'Ödeme işlemi başarısız');
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        submit: error.message || 'Ödeme işlemi sırasında bir hata oluştu'
      }));
    } finally {
      setLoading(false);
    }
  };
  const defaultInstallments = [
    { count: 1, totalAmount:totalAmount},
    { count: 2, totalAmount: totalAmount  },
    { count: 3, totalAmount: totalAmount },
    
  ];
  
  {loading && <p>Yükleniyor...</p>}
      {error && <p>Hata: {error}</p>}
      {paymentSuccess && <p>Ödeme başarıyla gerçekleşti!</p>}
  return (
    <>
    <div className='payment-title'>
    <h2>Ödeme Sayfası</h2>
    </div>
    <div className={`payment-page ${isFormActive ? "form-active" : ""}`}>
      <div className="payment-container">
        <div className="payment-form">
          <div className="commission-info">
          
          <button onClick={() => setIsOpen(true)}>Komisyon Oranları</button>
          {commissionRate.length > 0 ? (
            
            <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} contentLabel="Komisyon Oranları"  className="ReactModal__Content"
            overlayClassName="ReactModal__Overlay">
              <h2>Komisyon Oranları</h2>
              <button className="close-button" onClick={() => setIsOpen(false)}>
                        &times;</button>
              <table>
                <thead>
                  <tr>
                    <th>Banka</th>
                    <th>1 Taksit</th>
                    <th>2 Taksit</th>
                    <th>3 Taksit</th>
                  </tr>
                </thead>
                <tbody>
                  {commissionRate.map((bank) => {
                    const taksit1 = bank.taksit.find((t) => t.count === 1)?.rate ?? '';
                    const taksit2 = bank.taksit.find((t) => t.count === 2)?.rate ?? '';
                    const taksit3 = bank.taksit.find((t) => t.count === 3)?.rate ?? '';
    
                    return (
                      <tr key={bank.name}>
                        <td>{bank.name}<img className='bank-img' src={bank.image} alt={bank.name}/></td>
                        <td>{taksit1}</td>
                        <td>{taksit2}</td>
                        <td>{taksit3}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Modal>
       

          ) : (
            <p>Komisyon oranı mevcut değil.</p>
          )}
          </div> 
          <form onSubmit={handlePaymentSubmit}>
            <div className="form-group">
              <label>Kart Numarası:</label>
              <input
                type="text"
                value={cardNumber}
                onChange={handleCardNumberChange}
                maxLength="16"
                placeholder="Kart numaranızı giriniz"
                required
              />
            </div>

            <div className="form-group">
              <label>Ad Soyad:</label>
              <input
                type="text"
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
                placeholder="Adınızı Soyadınızı giriniz"
                required
              />
            </div>

            <div className="form-row">
            <div className="form-group small-group">
              <label>Son Kullanma Tarihi:</label>
              <input
                type="text"
                value={expiryDate}
                onChange={handleExpiryDateChange}
                placeholder="AA/YY"
                maxLength="5"
                required
              />
            </div>
            <div className="form-group small-group">
              <label>CVC:</label>
              <input
                type="text"
                value={cvc}
                onChange={handleCvcChange}
                placeholder="CVC"
                maxLength="3"
                required
              />
            </div>
            </div>
            <button type="submit" disabled={loading} className='payment-button'>
              {loading ? 'İşlem Yapılıyor...' : 'Ödeme Yap'}
            </button>
          </form>
         
          </div>
   
          <div className='payment-right'>
          {cardNumber.length >= 8 && (
            <div className="installment-options">
      <h3>Taksit Seçenekleri</h3>
      <table className="installment-table">
        <thead>
          <tr>
            <th>Taksit Sayısı</th>
            <th>Aylık Ödeme</th>
            <th>Banka Komisyonu</th>
          </tr>
        </thead>
        <tbody>
        {(cardNumber.length < 8 ? defaultInstallments : installments).map((installment) => (
            <tr key={installment.count}>
              <td> <label>
                <input
                  type="radio"
                  name="installment"
                  value={installment.count}
                  checked={selectedInstallment === installment.count}
                  onChange={handleInstallmentChange}
                />
               {installment.count} Taksit</label>
              </td>
              <td>
                <span>{installment.totalAmount} TL x {installment.count} </span>
              </td>
              <td>
                <span>{installment.rate} %</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}
       
          </div> 
        
        </div>
      </div>
      </>
  );
};

export default Payment;