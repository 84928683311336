import React, { useContext, useEffect, useState } from "react";
import { InstitutionalContext } from "../../context/InstitutionalContext";
import "./Institutional.css";

const Institutional=()=>{
    const{institutionals, institutionalsCategori, fetchInstitutional} = useContext(InstitutionalContext);
    const [selectedCategory, setSelectedCategory] = useState(null);
    

    useEffect(() => {
      if (!selectedCategory) {
        fetchInstitutional("hakkimizda");
      }
    }, [selectedCategory]);
  
    const handleCategoryClick = (sef) => {
      setSelectedCategory(sef);
      fetchInstitutional(sef);
    };
    
   


return(
    <div className="inst-page-wrapper">
    
    <div className="inst-categories-section">
     <h2>Kurumsal</h2>
      <ul>
        {institutionalsCategori.map((category) => (
          <li
          key={category.id}
          className={`inst-category-item ${selectedCategory === category.sef ? "active" : ""}`}
          onClick={() => handleCategoryClick(category.sef)}
        >
            {category.baslik}
          </li>
        ))}
      </ul>
    </div>

    
    <div className="inst-content-section">
     
    {institutionals ? (
                    <div>
                        <h3>{institutionals.baslik}</h3>
                        
                        <div
                            dangerouslySetInnerHTML={{ __html: institutionals.aciklama }}
                        />
                    </div>
                ) : (
                    <p>İçerik yükleniyor...</p>
                )}
            </div>
  </div>
);
};





export default Institutional;