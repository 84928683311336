import React, { createContext, useReducer, useContext } from 'react';

import axios from 'axios';

const initialState = {
    cardHolderName: '',
    cardNumber:'',
    expiryDate: '',
    cvc:'',
    totalAmount: '',
    installments: [],
    commissionRate: [],
    selectedInstallment: '',
    paymentSuccess: false,
    loading: false,
    error: null,

  };
  
const paymentReducer = (state, action) => {
    switch (action.type) {
      case 'SET_CARD_NUMBER':
        return { ...state, cardNumber: action.payload };
    case 'SET_CARD_HOLDER_NAME':
        return { ...state, cardHolderName: action.payload };
      
    case 'SET_EXPIRY_DATE':
        return { ...state, expiryDate: action.payload };
      
    case 'SET_CVC':
        return { ...state, cvc: action.payload };  
  
      case 'FETCH_INSTALLMENTS_REQUEST':
        return { ...state, loading: true };
  
      case 'FETCH_INSTALLMENTS_SUCCESS':
        return { ...state, loading: false, installments: action.payload };
  
      case 'FETCH_INSTALLMENTS_ERROR':
        return { ...state, loading: false, error: action.payload };
  
      case 'FETCH_COMMISSION_REQUEST':
        return { ...state, loading: true };
  
      case 'FETCH_COMMISSION_SUCCESS':
        return { ...state, loading: false, commissionRate: action.payload };
  
      case 'FETCH_COMMISSION_ERROR':
        return { ...state, loading: false, error: action.payload };
  
      case 'SET_SELECTED_INSTALLMENT':
        return { ...state, selectedInstallment: action.payload };
      case 'SET_TOTAL_AMOUNT':
        return { ...state, totalAmount: action.payload };
      case 'PAYMENT_REQUEST':
        return { ...state, loading: true, paymentSuccess: false, error: null };
        
      case 'PAYMENT_SUCCESS':
        return { ...state, loading: false, paymentSuccess: true };
        
      case 'PAYMENT_ERROR':
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };

export  const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentReducer, initialState);

  
  const setCardNumber = (number) => {
    dispatch({ type: 'SET_CARD_NUMBER', payload: number });
  };
  const setCardHolderName = (name) => {
    dispatch({ type: 'SET_CARD_HOLDER_NAME', payload: name });
  };

  const setExpiryDate = (month) => {
    dispatch({ type: 'SET_EXPIRY_DATE', payload: month });
  };

  const setCvc = (cvc) => {
    dispatch({ type: 'SET_CVC', payload: cvc });
  };

  const setTotalAmount = (total) => {
    dispatch({ type: 'SET_TOTAL_AMOUNT', payload: total });
  };

  
const calculateMonthlyPayment = (totalAmount, installmentCount, installmentRate) => {
  if (installmentCount > 0) {
    return ((totalAmount*(1+installmentRate/100))/installmentCount).toFixed(2); 
  }
  return 0; 
};
  const fetchCommissionRates = async () => {
    dispatch({ type: 'FETCH_COMMISSION_REQUEST' });
    try {
      const tkn= localStorage.getItem('tkn');
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.bikuyum.com/api/bank_list',{
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Tkn':`${tkn}`, 
        'Content-Type': 'application/json',
        
      },
     
    });
      const data = await response.json();
      
      if (Array.isArray(data) && data.length > 0) {
        const formattedData = data.map(bank => {
            return {
                name: bank.name,
                image:bank.image,
                taksit: Object.entries(bank.taksit).map(([count, rate]) => ({
                    count: parseInt(count),
                    rate: rate,
                
                }))
            };
        });
        dispatch({ type: 'FETCH_COMMISSION_SUCCESS', payload: formattedData });
    } else {
      throw new Error('Geçersiz veri formatı');
    }
  }catch (error) {
      console.error('API çağrısı sırasında hata oluştu:', error);
      dispatch({ type: 'FETCH_COMMISSION_ERROR', payload: error.message });
    }
  };

  const fetchInstallments = async (card_number) => {
   
    const cardDetails = {
        card_number: card_number, 
      };
      
    try {
      const token = localStorage.getItem('token');
      const tkn= localStorage.getItem('tkn');
      const response = await fetch('https://api.bikuyum.com/api/card_info', {
        method:'POST',
        headers:{
          'Authorization': `${token}`,
          'Tkn':`${tkn}`, 
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(cardDetails),
      });

      
      const data = await response.json();
      
      const formattedInstallments = Object.entries(data.installment_rates).map(
        ([count, rate]) => ({
          count: parseInt(count), 
          rate: rate, 
          totalAmount: calculateMonthlyPayment(state.totalAmount, parseInt(count), rate),
        })
      );

      dispatch({ type: 'FETCH_INSTALLMENTS_SUCCESS', payload:formattedInstallments });
    } catch (error) {
      dispatch({ type: 'FETCH_INSTALLMENTS_ERROR', payload: error.message });
    }
  };
  
  const setSelectedInstallment = (installment) => {
    dispatch({ type: 'SET_SELECTED_INSTALLMENT', payload: installment });
  };
  const paymentResult = async (paymentData) => {
  dispatch({ type: 'PAYMENT_REQUEST' });
  const token = localStorage.getItem('token');
  const tkn = localStorage.getItem('tkn');
  
  try {
    const response = await fetch('https://api.bikuyum.com/api/payment/success', {
      method: 'POST',
      headers: {
        'Authorization': `${token}`,
        'Tkn': `${tkn}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentData)
    });

    if (!response.ok) {
      throw new Error('Ödeme doğrulama başarısız');
    }

    const result = await response.json();
    dispatch({ type: 'PAYMENT_SUCCESS' });
    return result;
    
  } catch (error) {
    dispatch({ type: 'PAYMENT_ERROR', payload: error.message });
    throw error;
  }
};
  
  


  const processPayment = async (paymentData) => {
    dispatch({ type: 'PAYMENT_REQUEST' });
    const token = localStorage.getItem('token');
    const tkn= localStorage.getItem('tkn');
    try {
      const response = await axios.post('https://api.bikuyum.com/api/send_order', paymentData,{
      method: 'POST',
      headers: {
        'Authorization': `${token}`,
        'Tkn':`${tkn}`, 
        'Content-Type': 'application/json',
        
      },
     
    });
    if (response.data.success) {
      dispatch({ type: 'PAYMENT_SUCCESS' });
      
      return {
        success: true,
        redirect_url: response.data.redirect_url, 
        orderId: response.data.order_id 
      };
    } else {
      dispatch({ type: 'PAYMENT_ERROR', payload: response.data.message });
      return {
        success: false,
        message: response.data.message || 'Ödeme işlemi sırasında hata oluştu'
      };
    }
  } catch (error) {
    console.error("Payment Error:", error);
    dispatch({ type: 'PAYMENT_ERROR', payload: error.message });
    return {
      success: false,
      message: error.message || 'Ödeme işlemi sırasında bir hata oluştu'
    };
  }
};


  return (
    <PaymentContext.Provider
      value={{
        state,
        setCardNumber,
        setCardHolderName,
        setExpiryDate,
        setCvc,
        setTotalAmount,
        fetchCommissionRates,
        fetchInstallments,
        setSelectedInstallment,
        processPayment,
        paymentResult,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

