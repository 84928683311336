import React, { useState, useEffect, useContext} from 'react';
import "./Card.css";
import { useNavigate } from 'react-router-dom';
import cardd from "./card.png";
import { CardContext } from "../../context/CardContext.js";
import Iller from "../../il-ilce.json";
import { UserContext } from '../../context/UserContext.js';
import Modal from 'react-modal';

Modal.setAppElement('#root'); 

const Card = () => {
  const navigate = useNavigate();
  const { formData: userData } = useContext(UserContext);
  const { cart, total, deleteCard, decreaseQuantity, increaseQuantity } = useContext(CardContext);
  
  const [formData, setFormData] = useState({
    ad: userData?.ad || '',
    soyad: userData?.soyad || '',
    email: userData?.email || '',
    telefon: userData?.telefon || '05',
    tckimlik: userData?.tckimlik || '',
    il: '',
    ilce: '',
    adres: userData?.adres || '',
    siparis_notu: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [iller, setIller] = useState([]);
  const [ilceler, setIlceler] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [salesTermsAccepted, setSalesTermsAccepted] = useState(false);
  const [deliveryTermsAccepted, setDeliveryTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');

  useEffect(() => {
    setIller(Iller.iller);
  }, []);

	const getTermsContent = () => {
    switch (currentTerm) {
      case 'kullanım-kosullari':
        return (
          <div>
            <h2>Kullanım Koşulları</h2>
            <p>4. ÜYELİK ŞARTLARI VE YÜKÜMLÜLÜKLERİ

            4.1. Üye olmak isteyen 18 yaşını doldurmuş  kullanıcının, Websitesi'nde bulunan işbu Üyelik Sözleşmesi'ni sunulan formu eksiksiz ve güncel olarak doldurmak  ve belirtilen şekilde  onaylayarak Üyelik başvurusunun "sitemiz" tarafından sms ve e posta ile onaylanması gerekmektedir...</p>
          </div>
        );
      case 'mesafeli-satis':
        return (
          <div>
            <h2>Mesafeli Satış Sözleşmesi</h2>
            <p>1.TARAFLAR
İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır...</p>
          </div>
        );
      case 'teslimat-iade':
        return (
          <div>
            <h2>Teslimat ve İade Sözleşmesi</h2>
            <p>GENEL:
 
1.Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız...</p>
          </div>
        );
      default:
        return null;
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.ad.trim()) errors.ad = 'Ad alanı zorunludur';
    if (!formData.soyad.trim()) errors.soyad = 'Soyad alanı zorunludur';
    if (!formData.email.trim()) errors.email = 'Email alanı zorunludur';
    if (!formData.telefon || formData.telefon.length < 11) errors.telefon = 'Geçerli bir telefon numarası giriniz';
    if (!validateTCKN(formData.tckimlik)) errors.tckimlik = 'Geçersiz TC Kimlik No';
    if (!formData.il) errors.il = 'İl seçimi zorunludur';
    if (!formData.ilce) errors.ilce = 'İlçe seçimi zorunludur';
    if (!formData.adres.trim()) errors.adres = 'Adres alanı zorunludur';
    return errors;
  };

  const validateTCKN = (tckn) => {
    if (tckn.length !== 11 || !/^\d+$/.test(tckn)) return false;
    if (tckn === "11111111111") return true;
    const digits = tckn.split('').map(Number);
    if (digits[0] === 0) return false;

    const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
    const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
    
    const tenthDigit = (oddSum * 7 - evenSum) % 10;
    if (tenthDigit !== digits[9]) return false;

    const eleventhDigit = digits.slice(0, 10).reduce((a, b) => a + b, 0) % 10;
    return eleventhDigit === digits[10];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'telefon') {
      handlePhoneInput(e);
      return;
    }

    if (name === 'tckimlik') {
      if (value[0] === '0') return;
      if (/^\d*$/.test(value) && value.length <= 11) {
        setFormData(prev => ({ ...prev, [name]: value }));
      }
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else if (value.length === 1 && value !== "0") {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue.substring(0, 11) }));
      } else {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue }));
      }
    }
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  };

  const handleIlChange = (event) => {
    const ilSecimi = event.target.value;
    setFormData(prev => ({ ...prev, il: ilSecimi, ilce: '' }));
    
    const secilenIl = iller.find((il) => il.il_adi === ilSecimi);
    setIlceler(secilenIl ? secilenIl.ilceler : []);
  };

  const handleIncreaseQuantity = (product) => {
    if (product.quantity < product.stok) {
      increaseQuantity(product);
    } else {
      alert("Bu üründen yeterli stok bulunmamaktadır.");
    }
  };

  const handleSubmit = async (event) => {
  event.preventDefault();
  
  if (!termsAccepted || !salesTermsAccepted || !deliveryTermsAccepted) {
    alert("Lütfen tüm sözleşmeleri kabul edin.");
    return;
  }

  const errors = validateForm();
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    window.scrollTo(0, 0);
    return;
  }

  try {
    navigate("/payment", { 
      state: { 
        total,
        customerForm: formData, 
        cart: cart
      }
    });
  } catch (error) {
    console.error('Hata:', error);
    alert('İşlem sırasında bir hata oluştu');
  }
};

  if (cart.length === 0) {
    return (
      <div className='card-container'>
        <div className='card'>
          <img src={cardd} alt="card-resim" />
          <p>Sepetinizde ürün bulunmamaktadır.</p>
          <a href="/"><button>Alışverişe Başla</button></a>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="checkout-page">
        <div className='card-product'>
          <div className='card-title'><span>Sepetim</span></div>
          <div className="product-listt">
            {cart.map((product, index) => (
              <div className='card-item' key={index}>
                <div className='card-product-header'>
                  <span>Satıcı: <a href={`/${product.magaza_sef}/all`}><span>{product.magaza_adi}</span></a></span>
                </div>
                <hr className='hr' />
                <div className='card-product-content'>
                  <div className='card-product-info'>
                    <a href={`/urun/${product.sef}`}>
                      <img src={`https://panel.bikuyum.com/upload/${product.image.i1}`} alt={product.baslik} className='product-imagee' />
                    </a>
                    <a href={`/urun/${product.sef}`}>
                      <p>{product.baslik}</p>
                      {product.size !== 0 && product.size !== 15 && (
                        <span>({product.size} numara 150 TL fiyat farkı eklenmiştir)</span>
                      )}
                    </a>
                  </div>
                  <div className='card-quantity'>
                    <button onClick={() => decreaseQuantity(product)}>-</button>
                    <p>{product.quantity || 1}</p>
                    <button onClick={() => handleIncreaseQuantity(product)}>+</button>
                  </div>
                  <div className='card-price'>
                    <p>{(product.fiyat !== undefined ? product.fiyat : 0).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}TL</p>
                  </div>
                  <div className='card-remove'>
                    <button className="delete-button" onClick={() => deleteCard(product.cart_id)}>🗑️</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="order-summary">
          <div className='order-summary-first'>
            <div className="summary-item">
              <span>Ara Tutar</span>
              <span>{total.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
            </div>
            <div className="summary-item">
              <span>Kargo Ücreti</span>
              <span>{cart.kargo_fiyati}TL</span>
            </div>
            <div className="summary-item-total">
              <span>Toplam Tutar</span>
              <span>{total.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
            </div>
          </div>

          <div className='order-summary-second'>
            <form className="checkout-form" onSubmit={handleSubmit}>
              <div className="checkout-form-group">
                <div>
                  <label>Ad*</label>
                  <input
                    type="text"
                    name="ad"
                    value={formData.ad}
                    onChange={handleInputChange}
                    placeholder="Ad*"
                    required
                  />
                  {formErrors.ad && <span className="error">{formErrors.ad}</span>}
                </div>
                <div>
                  <label>Soyad*</label>
                  <input
                    type="text"
                    name="soyad"
                    value={formData.soyad}
                    onChange={handleInputChange}
                    placeholder="Soyad*"
                    required
                  />
                  {formErrors.soyad && <span className="error">{formErrors.soyad}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <div>
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email*"
                    required
                  />
                  {formErrors.email && <span className="error">{formErrors.email}</span>}
                </div>
                <div>
                  <label>Telefon*</label>
                  <input
                    type="tel"
                    name="telefon"
                    value={formatPhoneNumber(formData.telefon)}
                    onChange={handleInputChange}
                    placeholder="Telefon*"
                    maxLength="14"
                    required
                  />
                  {formErrors.telefon && <span className="error">{formErrors.telefon}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <div className='tckimlik'>
                  <label>Fatura İçin TC Kimlik No*</label>
                  <input
                    type="text"
                    name="tckimlik"
                    value={formData.tckimlik}
                    onChange={handleInputChange}
                    placeholder="Fatura İçin TC Kimlik No"
                    maxLength="11"
                    required
                  />
                  {formErrors.tckimlik && <span className="error">{formErrors.tckimlik}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <select
                  name="il"
                  value={formData.il}
                  onChange={handleIlChange}
                  required
                >
                  <option value="">Lütfen Bir İl Seçiniz</option>
                  {iller.map((il, index) => (
                    <option key={index} value={il.il_adi}>
                      {il.il_adi}
                    </option>
                  ))}
                </select>
                {formErrors.il && <span className="error">{formErrors.il}</span>}

                <select
                  name="ilce"
                  value={formData.ilce}
                  onChange={handleInputChange}
                  required
                  disabled={!formData.il}
                >
                  <option value="">Lütfen Bir İlçe Seçiniz</option>
                  {ilceler.map((ilce, index) => (
                    <option key={index} value={ilce.ilce_adi}>
                      {ilce.ilce_adi}
                    </option>
                  ))}
                </select>
                {formErrors.ilce && <span className="error">{formErrors.ilce}</span>}
              </div>

              <div className="checkout-form-group">
                <label>Sipariş Adresiniz*</label>
                <textarea
                  name="adres"
                  value={formData.adres}
                  onChange={handleInputChange}
                  placeholder="Sipariş Adresiniz"
                  required
                />
                {formErrors.adres && <span className="error">{formErrors.adres}</span>}
              </div>

              <div className="checkout-form-group">
                <label>Sipariş Notunuz</label>
                <textarea
                  name="siparis_notu"
                  value={formData.siparis_notu}
                  onChange={handleInputChange}
                  placeholder="Sipariş Notunuz"
                />
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="kullanim-kosullari"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <label htmlFor="kullanim-kosullari">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('kullanım-kosullari');
                    setModalIsOpen(true);
                  }}>Kullanım Koşulları</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="mesafeli-satis"
                  checked={salesTermsAccepted}
                  onChange={() => setSalesTermsAccepted(!salesTermsAccepted)}
                />
                <label htmlFor="mesafeli-satis">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('mesafeli-satis');
                    setModalIsOpen(true);
                  }}>Mesafeli Satış</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="teslimat-iade"
                  checked={deliveryTermsAccepted}
                  onChange={() => setDeliveryTermsAccepted(!deliveryTermsAccepted)}
                />
                <label htmlFor="teslimat-iade">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('teslimat-iade');
                    setModalIsOpen(true);
                  }}>Teslimat ve İade</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <button type="submit" className='button-apply'>Siparişi Tamamla</button>
            </form>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => {
                setModalIsOpen(false);
                setCurrentTerm('');
              }}
              contentLabel="Sözleşme Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <button
                className="close-button"
                onClick={() => {
                  setModalIsOpen(false);
                  setCurrentTerm('');
                }}
              >
                ×
              </button>
              {getTermsContent()}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;