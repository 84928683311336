import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { Container, Grid } from "@mui/material";
import Register from './pages/register/Register.jsx';
import Home from './pages/home/Home.jsx';
import OpenStore from './pages/openStore/OpenStore.js';
import Footer from './components/Footer.js';
import Login from './pages/login/Login.js';
import ProductDetails from "./pages/product/ProductDetails.js";
import ProductList from "./pages/product/ProductList.js";
import ForgotPassword from './pages/forgotPassword/ForgotPassword.js';
import Card from "./pages/card/Card.js";
import StoreProduct from "./pages/stores/StoreProduct.js";
import User from "./pages/user/User.js";
import CategoriesProduct from "./pages/categories/CategoriesProduct.js";
import Favorite from "./pages/product/Favorite.js";
import OrderDetails from "./pages/card/OrderDetails.js";
import SearchBar from "./components/SearchBar.js";
import Layout from "./layout/Layout.js";
import Payment from "./pages/card/Payment.js";
import SearchResults from "./components/SearchResults.js";
import Institutional from "./pages/agreement/Institutional.js";
import CampaignProduct from "./pages/product/CampaignProducts.js";
import Panel from "./pages/admin/Panel.js";
import Magaza from "./pages/magaza/Magaza.js";
import PaymentResult from "./pages/card/PaymentResult.js";
import { v4 as uuidv4 } from 'uuid';


const createToken = () => {
  
  const token = `bikuyum-${uuidv4()}`; 

  localStorage.setItem('tkn', token);  
  return token;
};
const getToken = () => {
  let token = localStorage.getItem('tkn');
  
  if (!token) {
    token = createToken();  
  }
  
  return token;
};
function App() {
  
 

  return (
    <Router>
      
      <Layout> 
    
    <Container maxWidth="xl" style={{ paddingBottom: '20px' }}>
      <Routes>
       
        <Route path="/" element={<Home/>} />
        <Route path="/:id" element={<CategoriesProduct/>} />
        
        <Route path="/favorite" element={<Favorite/>}/>
        <Route path="/productList" element={<ProductList/>} />
        <Route path="/kampanyaliurunler" element={<CampaignProduct/>}/>
        <Route path="/urun/:sef" element={<ProductDetails/>} />
        <Route path="/searchBar" element={<SearchBar/>}/>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user" element={<User/>}/>
        <Route path="/orderDetails" element={<OrderDetails/>}/>
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/openStore" element={<OpenStore />} />
        <Route path="/card" element={<Card />} />
        <Route path="/payment" element={<Payment/>}/>
        <Route path="/payment-result/:status" element={<PaymentResult />} />
        <Route path="/:kullanici_id/:kategori" element={<StoreProduct />} />
        <Route path="/search" element={<SearchResults/>}/>
        <Route path="/institutional" element={<Institutional/>}/>
        <Route path="/panel" element={<Panel />} />
        <Route path="/magaza" element={<Magaza />} />
      </Routes>
     
      </Container>
      </Layout>
      
      
    </Router>
  );
}

export default App;
