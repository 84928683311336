import jwt_decode from "jwt-decode";
export const setAuthToken = (token) => {
  localStorage.setItem("token", token);
};
export const getAuthTokenFromStorage = () => {
  return localStorage.getItem("token");
};
export const getDecodedAuthToken = () => {
  const token = getAuthTokenFromStorage();
  const decodedToken = jwt_decode(token);
  return decodedToken;
};
export const removeTokenFromStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("studentToken");
  localStorage.removeItem("nameAndSurname");
  localStorage.removeItem("groupId");
  localStorage.removeItem("currentDate");
};
export const removeAuthTokenFromStorage = () => {
  localStorage.removeItem("token");
};
function isJWT(token) {
  const parts = token.split('.');
  return Array.isArray(parts) && parts.length === 3;
}
export const authTokenCheck = () => {
  const token = localStorage.getItem("token"); 
  return token !== null && isJWT(token);
};
export const setResetAuthToken = (token) => {
  localStorage.setItem("reset-token", token);
};
export const getResetAuthToken = () => {
  return localStorage.getItem("reset-token");
};