import React, { createContext, useReducer, useEffect} from 'react';
const initialState={
    categories: [],
    error:null,
    loading:true,
    
    
};

const categoriesReducer=(state, action)=>{
    switch(action.type){
        case 'FETCH_CATEGORY_REQUEST':
            return{...state, loading:true};
        case 'FETCH_CATEGORIES_SUCCESS':
            return { ...state, categories: action.payload, loading: false };
         case 'FETCH_CATEGORIES_FAILURE':
            return { ...state, error: action.payload, loading: false };
             default:
      return state;
  }
};
export const CategoryContext= createContext();
export const CategoryProvider=({children})=>{
    const [state, dispatch]=useReducer(categoriesReducer, initialState);
    useEffect(()=>{
        const fetchCategories = async () => {
            try{
                const token = localStorage.getItem('token');
                const tkn= localStorage.getItem('tkn');
                const response=await fetch('https://api.bikuyum.com/api/categories',{
                method: 'GET',
                headers: {
                  'Authorization': `${token}`,
                  'Tkn':`${tkn}`, 
                  'Content-Type': 'application/json',
                  
                },
               
              });
                const data=await response.json();
                dispatch({ type:'FETCH_CATEGORIES_SUCCESS', payload: data });
            }
            catch(error){
                dispatch({type: 'FETCH_CATEGORIES_FAILURE', payload: error.message});
            }
           
        };
        fetchCategories();
    }, []);

    const selectedCategory = (kategori) => {
        dispatch({ type: 'SELECT_CATEGORY', payload: kategori });
      };
     
      

    return(
       <CategoryContext.Provider value={{state, selectedCategory, dispatch}}>
        {children}
       </CategoryContext.Provider>
    );
};
    


