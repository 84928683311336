import React, { useEffect, useState, useContext} from "react";
import "../assest/Header.css";
import bikuyumlogo from '../img/logo.webp';
import { useNavigate } from "react-router-dom";
import Categories from "../pages/categories/Categories.js";
import { CardContext } from "../context/CardContext.js";
import { removeTokenFromStorage } from "../store/auth-store.js";
import { FaHeart } from 'react-icons/fa';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import goldicon from '../components/gold-ingot.png';
import SearchBar from "./SearchBar.js";
import { Container } from "@mui/material";





const Header = () => {
    
    const navigate = useNavigate();
    const {cart} =useContext(CardContext);
    const [exchangeRates, setExchangeRates] = useState({
      USD: null,
      EUR: null,
      GOLD: null,
    });
    const words = [
      "💎 Rengarenk Küpe Modelleri. Bu Işıltıyı Hakediyorsunuz💎",
      "💎 Saat Modelleri Kombinin Vazgeçilmezi💎",
      "💎 Zarif Kolye Modelleri, Birbirinden Farklı, Birbirinden Özgün 💎",
      "💎 Yüzük Modelleri, Her Tarza, Her Modaya 💎",
      "💎 Kelepçe Modelleri, Zarif Değerli 💎",
      "💎 Bileklik Modelleri İnci, İtalya, Zarif 💎"
    ];
  
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [showAuthDropdown, setShowAuthDropdown] = useState(false);
    
    
    useEffect(() => {
      const fetchExchangeRates = async () => {
        try {
          
          const response = await fetch('https://api.bikuyum.com/api/exchange_rates');
          const data = await response.json();
          
          setExchangeRates({
            USD: data.USD.selling,
            EUR: data.EUR.selling,
            GOLD: data.GOLD.selling, 
          });
        } catch (error) {
          console.error('Döviz kurları alınamadı:', error);
        }
      };
  
      fetchExchangeRates();
      const intervalId = setInterval(fetchExchangeRates,  60000);

      return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token); 
    }, [navigate]);
  
   
    const handleLogin = () => {
      
      navigate("/login");
      
    };

    const handleLogout = () => {
      removeTokenFromStorage(); 
      
      setIsLoggedIn(false); 
      navigate("/"); 
     
    };
    

    const cartCount = cart.reduce((total, product) => total + (product.quantity || 1), 0);

   
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) =>
        prevIndex === words.length - 1 ? 0 : prevIndex + 1
      );
    }, 1500); 

    return () => clearInterval(interval); 
  }, [words.length]);
  const toggleDropdown = () => {
    setShowAuthDropdown((prev) => !prev); 
  };

  const closeDropdown = () => {
    setShowAuthDropdown(false); 
  };

    return (
     <>
     <Container>
    <div className="header">
      
      <div className="top-bar">
           <div className="top-bar-left">
           <a href={'/institutional'}>Kurumsal</a>
           <a href={'https://panel.bikuyum.com/magaza/giris-yap'}>Mağaza Girişi</a>
           </div>

            <div  className="top-bar-center">
              <span className="center-text">
                 {words[currentWordIndex]}
              </span>
            </div>
            <div  className="top-bar-right">
            
             <img src={goldicon} alt="Gold" style={{ width: '20px' }} />
             <span>Gram Altın <span className="doviz">  {exchangeRates.GOLD} TL</span></span>
             <AttachMoneyIcon style={{ color: 'green', verticalAlign: 'middle', fontSize:'18px' }} />
             <span> Dolar <span className="doviz"> {exchangeRates.USD} TL</span></span>
            <EuroIcon style={{ color: 'blue', verticalAlign: 'middle',  fontSize:'16px' }} />
            <span > Euro <span className="doviz">{exchangeRates.EUR} TL</span></span>
             
            
          </div>
        </div>
        
        <div className="center">
        <div className="logo-div" >
        <a  href="/">
          <img className="logo" src={bikuyumlogo} alt=""  />
        </a>
        </div>
        
          <div className="searchbarr"><SearchBar></SearchBar></div>
          <div className="cart-header">
            <div>
            <div className="auth-dropdown" 
                         onMouseEnter={() => window.innerWidth > 768 && setShowAuthDropdown(true)}
                      onMouseLeave={() => window.innerWidth > 768 && setShowAuthDropdown(false)} 
                    style={{ position: 'relative' }}
                >
                   <a> <i className="fas fa-user"   onClick={() => {
              if (!isLoggedIn) toggleDropdown(); 
              else navigate("/user"); 
            }}></i></a>
          {isLoggedIn ? (
            <>
             <span onClick={() => navigate("/user")}> Hesabım </span>
             <div className="auth-dropdown-menu" style={{ position: 'absolute', left: '0', top: '100%', zIndex: 1000 }}>
               <div className="dropdown-item" 
               
               onClick={() => {
                closeDropdown(); 
                handleLogout(); 
              }}>
                 Çıkış Yap
               </div>
             </div>
           
           </>
         ) : (
         
                    <span className="auth-title"> Giriş Yap / Kayıt Ol</span>)}
                    {showAuthDropdown &&  !isLoggedIn && (

                        <div className="auth-dropdown-menu"
                            style={{ position: 'absolute', left: '0', top: '100%', zIndex: 1000 }}
                        >
                            <div className="dropdown-item"  onClick={() => {
                closeDropdown();  navigate("/register");}}>
                                Kayıt Ol
                            </div>
                            <div className="dropdown-item"  onClick={() => {
                closeDropdown();
                handleLogin(); 
              }}>
                                Giriş Yap
                            </div>
                            <div className="dropdown-item"  onClick={() => {
                closeDropdown(); 
                navigate("/openStore"); 
              }}>
                                Mağaza Aç
                            </div>
                            
                        </div>
                    )}
                </div>
              
             </div>   
      <div>
      <div className="header-favorite">
      <a href="/favorite" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
                <FaHeart style={{ fontSize:'16px', marginRight: '5px', color: 'red' }} /> 
                <span>Favoriler</span>
            </a>
          
      </div>
     
      </div>
          <div className="cart">
         
            <div 
                className="cart-icon" 
                onMouseEnter={() => setIsHovering(true)} 
                onMouseLeave={() => setIsHovering(false)}
                onClick={() => setIsHovering(false)} 
                style={{ position: 'relative' }} 
            >
                <i className="fas fa-shopping-cart" onClick={() => navigate("/card")}></i>
                 <span onClick={() => navigate("/card")}> Sepetim</span>
                 <span className="cart-count" >{cartCount}</span>
                 
                {isHovering && (
                  
                    <div className="cart-dropdown">
                        {cart.length === 0 ? (
                            <p>Sepetiniz boş</p>
                        ) : (  <>
                            <h4>Sepetim ({cartCount})</h4>
                        {  cart.map(item => (
                           
                            <div key={item.id} className="cart-item">
                                <div className="cart-item-image">
                                <a href={`urun/${item.sef}`}><img src={`https://panel.bikuyum.com/upload/${item.image.i1}`} alt={item.name} /></a>
                                   
                                </div>
                                <div className="cart-item-details">
                                <a href={`urun/${item.sef}`}><p className="product-title">{item.baslik}</p></a>
                                    <p className="cart-item-details-price"> {item.fiyat} TL</p>
                                </div>
                            </div>
                        ))}
                        <button className="cart-button" onClick={() => navigate("/card")}>
                       
                            Sepete Git
                        </button>
                        </>
                        
                    )}
                        
                        
                    </div>
                )}
            </div>
            
          </div>
      </div>
      </div>
        <div className="menu">
          <Categories/>
        </div>
       
        </div>
        </Container>
    
    </>
    );
};
export default Header;