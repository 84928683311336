import React, {useState, useContext} from "react";
import "./User.css"
import OrderDetails from "../card/OrderDetails.js";
import { UserContext } from "../../context/UserContext.js";


const User =()=>{
  const { formData, handleInputChange, handleSubmit, loading } = useContext(UserContext); 
    
  const [activeTab, setActiveTab] = useState('settings'); 
    
    const handleTabClick = (tab) => {
    setActiveTab(tab); 
      };
      
      
      if (loading) {
        return <p>Yükleniyor...</p>; 
      }

    return(
      
        <div className="user-container">
          
            <div >
              <h2>Merhaba,  {formData.ad} {formData.soyad} </h2>
              <p>Hesap bilgilerinizi ve sipariş geçmişinizi bu sayfadan yönetebilirsiniz</p>
            </div>
            <div>

             <div className="settings-navigation">
        <button  className={activeTab === 'settings' ? 'active-tab' : ''}
          onClick={() => handleTabClick('settings')}>Ayarlar</button>
        <button  className={activeTab === 'orders' ? 'active-tab' : ''}
          onClick={() => handleTabClick('orders')}>Siparişlerim</button>
      </div>
      {activeTab === 'settings' && (
      
      <form onSubmit={handleSubmit} className="account-form">
        <div className="form-user">
          <label>Ad </label>
          <input
            type="text"
            name="ad"
            value={formData.ad}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-user">
          <label>Soyad </label>
          <input
            type="text"
            name="soyad"
            value={formData.soyad}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-user">
          <label>Email </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-user">
          <label>Telefon </label>
          <input
            type="tel"
            name="telefon"
            value={formData.telefon}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-user">
          <label>Şifreniz</label>
          <input
            type="password"
            name="sifre"
            value={formData.sifre}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-user">
          <label>Sipariş Adresiniz </label>
          <textarea
            name="adres"
            value={formData.adres}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="update-button">
          Bilgilerimi Güncelle
        </button>
      </form>
       )}
      </div>
       {activeTab === "orders" && (
          <div className="orders-container">
          <OrderDetails/>
        </div>
      )}
    </div>
        
       
    );
}
export default User;